import { combineReducers, configureStore, createSelector, createSlice } from '@reduxjs/toolkit';
import type { ConfigureStoreOptions } from '@reduxjs/toolkit';

//! HAS TO BE SAME STRUCTURE AS ADMIN/CUSTOMER APPS
interface AppSlice {
  user: {
    emailAddress: string;
    organization: {
      organizationId: number | null;
    };
    userRoles: {
      role: {
        roleId: number;
      };
    }[];
  };
}

const initialState: AppSlice = {
  user: {
    emailAddress: '',
    organization: {
      organizationId: null,
    },
    userRoles: [],
  },
};

//! same shape as admin/customer app
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
});

//? for typing + testing
export const storeFactory = (preloadedState?: ConfigureStoreOptions['preloadedState']) => {
  return configureStore({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    preloadedState,
    reducer: combineReducers({
      [appSlice.name]: appSlice.reducer,
    }),
  });
};

export type Store = ReturnType<typeof storeFactory>;
export type State = ReturnType<Store['getState']>;

const selectSlice = (state: State) => state?.app;

export const selectConfigCatInfo = createSelector(selectSlice, (slice) => {
  const user = slice?.user;

  return {
    emailAddress: user?.emailAddress,
    organizationId: user?.organization?.organizationId,
    roleId: user?.userRoles?.[0]?.role?.roleId,
  };
});
