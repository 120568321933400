import { forwardRef } from 'react';
import type { ActionIconProps as MantineActionIconProps } from '@mantine/core';
import { ActionIcon as MantineActionIcon } from '@mantine/core';
import type { PolymorphicComponentProps } from '@mantine/utils';

type ActionIconProps = PolymorphicComponentProps<'button', Omit<MantineActionIconProps, 'sx'>>;

export const ActionIcon = forwardRef<HTMLButtonElement, ActionIconProps>((props, ref) => {
  return <MantineActionIcon ref={ref} {...props} />;
});

ActionIcon.displayName = 'ActionIcon';
