import { useTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';

const THEME_COLOR = '#000000';

export const MetaInfo = () => {
  const { t } = useTranslation();

  const defaultTitle = t('Highlight');
  const descText = t('Highlight');

  return (
    <DefaultSeo
      title={defaultTitle}
      titleTemplate="%s | Highlight"
      defaultTitle={defaultTitle}
      description={descText}
      openGraph={{
        type: 'website',
        locale: 'en_US',
        url: 'https://warehouse-portal.letshighlight.com/',
        site_name: 'Highlight',
      }}
      twitter={{
        site: '@letshighlight',
        cardType: 'summary_large_image',
      }}
      languageAlternates={[
        { hrefLang: 'en', href: 'https://warehouse-portal.letshighlight.com/en' },
      ]}
      additionalMetaTags={[
        // PWA
        {
          name: 'application-name',
          content: descText,
        },
        { name: 'theme-color', content: THEME_COLOR },

        {
          name: 'viewport',
          content:
            'minimum-scale=1, initial-scale=1, maximum-scale=1, width=device-width, viewport-fit=cover, user-scalable=no',
        },
        {
          name: 'format-detection',
          content: 'telephone=no',
        },
        {
          name: 'mobile-web-app-capable',
          content: 'yes',
        },

        // apple
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'default',
        },
        {
          name: 'apple-mobile-web-app-title',
          content: descText,
        },

        // ms
        // {
        //   name: 'msapplication-config',
        //   content: '/icons/browserconfig.xml',
        // },
        {
          name: 'msapplication-TileColor',
          content: THEME_COLOR,
        },
        {
          name: 'msapplication-tap-highlight',
          content: 'no',
        },
      ]}
    />
  );
};
