export const TAG_TYPES = {
  CLIENT: 'Client',
  HIGHLIGHTER: 'Highlighter',
  HIGHLIGHTER_ANSWER: 'HighlighterAnswer',
  HIGHLIGHTER_BOX: 'HighlighterBox',
  HIGHLIGHTER_NOTE: 'HighlighterNote',
  HIGHLIGHTER_TAG: 'HighlighterTag',
  HL_SURVEY: 'HLSurvey',
  SURVEY_INSTANCE: 'SurveyInstance',
  TAG: 'Tag',
  REFERRAL_CODE: 'ReferralCode',
  RECRUITING: 'Recruiting',
  QUESTIONS: 'Questions',
} as const;
