import type { GroupProps as MantineGroupProps } from '@mantine/core';
import { Group as MantineGroup } from '@mantine/core';
import type { PolymorphicComponentProps } from '@mantine/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GroupProps = PolymorphicComponentProps<any, Omit<MantineGroupProps, 'sx'>>;

export const Group = (props: GroupProps) => {
  return <MantineGroup {...props} />;
};
