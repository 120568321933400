import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const name = 'app';

// session credentials
interface Credentials {
  email: string;
  token: string;
}

export interface UserSlice {
  userId: string;
  userName: string;
  emailAddress: string;
}
export interface AppSliceState {
  user: UserSlice;
  token: string;
  landingRoute: string;
}

const initialState: AppSliceState = {
  user: {
    userName: '',
    emailAddress: '',
    userId: '',
  },
  token: '',
  //This is the default landing route for the app
  landingRoute: '/home',
};

export const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLandingRoute: (state, action: PayloadAction<Pick<AppSliceState, 'landingRoute'>>) => {
      state.landingRoute = action.payload.landingRoute;
    },
    setCredentials: (state, action: PayloadAction<Credentials>) => {
      const { email, token } = action.payload;

      state.user.emailAddress = email;
      state.token = token;
    },
    setUserState: (state, action: PayloadAction<UserSlice>) => {
      state.user = action.payload;
    },
    signOutUser: (state) => {
      state.user = initialState.user;
      state.token = initialState.token;
    },
  },
});

export const { setCredentials, setUserState, signOutUser, setLandingRoute } = appSlice.actions;
