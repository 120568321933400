import type { GridProps as MantineGridProps } from '@mantine/core';
import { Grid as MantineGrid } from '@mantine/core';

type GridProps = Omit<MantineGridProps, 'sx'>;

export const Grid = (props: GridProps) => {
  return <MantineGrid {...props} />;
};

Grid.Col = MantineGrid.Col;
