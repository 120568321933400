/* eslint-disable no-console */
export const LOGGER_SEVERITY = {
  DEBUG: 'debug',
  INFO: 'info',
  LOG: 'log',
  WARN: 'warn',
  ERROR: 'error',
} as const;

type severity = (typeof LOGGER_SEVERITY)[keyof typeof LOGGER_SEVERITY];
interface LoggerParams {
  message: string;
  tag?: string;
  severity?: severity;
  payload?: object;
}

export function logger(params: LoggerParams) {
  try {
    const { message, severity, payload = {}, tag = '' } = params;
    const msgString = tag ? `[${tag}] ${message}` : message;

    if (process.env.NODE_ENV === 'development') {
      // pretty string for terminal output
      console.log(`${severity} - [${tag}] ${message}; payload: ${JSON.stringify(payload)}`);
      return;
    }

    // string that displays nicely for Datadog
    const log = JSON.stringify({ payload, message: msgString, tag });

    switch (severity) {
      case LOGGER_SEVERITY.ERROR:
        console.error(log);
        break;

      case LOGGER_SEVERITY.WARN:
        console.warn(log);
        break;

      case LOGGER_SEVERITY.DEBUG:
        console.debug(log);
        break;

      default:
        console.log(log);
        break;
    }
  } catch (error) {
    console.error(JSON.stringify({ message: 'logger - error', error }));
  }
}

interface CreateLoggersParams {
  tag: string;
}

export function createLoggers(params: CreateLoggersParams) {
  const log = (message: string, payload?: object) => {
    logger({
      severity: LOGGER_SEVERITY.LOG,
      tag: params.tag,
      message,
      payload,
    });
  };

  const debug = (message: string, payload?: object) => {
    logger({
      severity: LOGGER_SEVERITY.DEBUG,
      tag: params.tag,
      message,
      payload,
    });
  };

  const warn = (message: string, payload?: object) => {
    logger({
      severity: LOGGER_SEVERITY.WARN,
      tag: params.tag,
      message,
      payload,
    });
  };

  const error = (message: string, payload?: object) => {
    logger({
      severity: LOGGER_SEVERITY.ERROR,
      tag: params.tag,
      message,
      payload,
    });
  };

  return {
    error,
    warn,
    debug,
    log,
  };
}
