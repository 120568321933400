import { useFeatureFlag, User } from 'configcat-react';

import type { FeatureFlagValue, SettingTypeOf } from '../types';

export interface ConfigCatFeatureFlagUserConfig {
  emailAddress?: string;
  roleId?: number;
  organizationId?: number | null;
}

export function useConfigCatFeatureFlag<T extends FeatureFlagValue>(
  key: string,
  defaultValue: T,
  userConfig: ConfigCatFeatureFlagUserConfig = {},
): { value: SettingTypeOf<T>; loading: boolean } {
  const { emailAddress = '', roleId, organizationId } = userConfig;

  const configCatUser = new User(emailAddress, emailAddress, undefined, {
    // custom
    role: roleId?.toString() ?? '',
    organization: organizationId?.toString() ?? '',
  });

  return useFeatureFlag(key, defaultValue, configCatUser);
}
