import process from 'process';

import React from 'react';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { GoogleTagManager } from '@next/third-parties/google';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';

import { ErrorBoundary } from '@highlight-cp/core-ui';
import { handleSDKKey } from '@highlight-cp/feature-flags';
import { createLoggers, initMonitoring } from '@highlight-cp/logger';
import { AuthGuard } from 'features/app/auth-guard';
import { MetaInfo } from 'features/app/meta-info';
import { reduxWrapper } from 'store';

const { debug, error } = createLoggers({ tag: 'App' });

const Layout = dynamic(() => import('features/app/layout'));

const ConfigCatProvider = dynamic(
  () => import('configcat-react').then((mod) => mod.ConfigCatProvider),
  {
    ssr: false,
  },
);

// development env
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  /* eslint-disable  */
  const ReactDOM = require('react-dom');

  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

function _App({ Component, ...rest }: AppProps) {
  const { store, props } = reduxWrapper.useWrappedStore(rest);
  const configCatSdkKey = handleSDKKey(rest.pageProps.configCatSdkKey);

  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    initMonitoring(rest.pageProps.datadog ?? {});
  }

  return (
    <ErrorBoundary>
      <ConfigCatProvider
        sdkKey={configCatSdkKey}
        options={{
          setupHooks: (hooks) => {
            hooks.on('clientReady', () => {
              debug('ConfigCat Client ready');
            });

            hooks.on('clientError', (err) => {
              error('ConfigCat Client error', { err });
            });
          },
        }}
      >
        <SessionProvider session={props.pageProps.session} refetchOnWindowFocus={false}>
          <Provider store={store}>
            <Layout>
              <MetaInfo />
              <AuthGuard>
                <>
                  <Component {...props.pageProps} />
                  <GoogleTagManager gtmId="GTM-M2VC8JB" />
                </>
              </AuthGuard>
            </Layout>
          </Provider>
        </SessionProvider>
      </ConfigCatProvider>
    </ErrorBoundary>
  );
}

export default appWithTranslation(_App);
