import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { TAG_TYPES } from './constants';

const restBaseQuery = fetchBaseQuery({
  baseUrl: '/api/',
});

export const emptyHighlighterApi = createApi({
  // TODO: rename this once everything is using package
  reducerPath: 'highlighterApiPkg',
  baseQuery: restBaseQuery,
  tagTypes: Object.values(TAG_TYPES),
  endpoints: () => ({}),
});
