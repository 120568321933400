import { useSelector } from 'react-redux';

import type { FeatureFlagValue, SettingTypeOf } from '../';
import { useConfigCatFeatureFlag } from '../config-cat';
import { selectConfigCatInfo } from './store';

export function useFeatureFlag<T extends FeatureFlagValue>(
  key: string,
  defaultValue: T,
): { value: SettingTypeOf<T>; loading: boolean } {
  const { emailAddress, organizationId, roleId } = useSelector(selectConfigCatInfo);

  return useConfigCatFeatureFlag(key, defaultValue, { emailAddress, roleId, organizationId });
}
