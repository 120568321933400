import { datadogLogs } from '@datadog/browser-logs';
import type { InternalContext } from '@datadog/browser-logs/cjs/domain/contexts/internalContext';
import { datadogRum } from '@datadog/browser-rum';

interface DDUserObject {
  id?: string;
  team?: string;
  org?: string;
  role?: string;
}

declare global {
  interface Window {
    DD_LOGS: {
      setUser: (user: DDUserObject) => void;
      getInternalContext: () => InternalContext;
    };
    DD_RUM: {
      setUser: (user: DDUserObject) => void;
      getInternalContext: () => InternalContext;
    };
  }
}

export interface MonitoringParams {
  environment: string;
  service: string;
  version: string;
  clientToken: string;
  applicationId: string;
  sessionSampleRate?: number;
  sessionReplaySampleRate?: number;
}

export const initMonitoring = (params: MonitoringParams) => {
  if (typeof window === 'undefined') return;

  const clientToken = params.clientToken;
  const applicationId = params.applicationId;
  const service = params.service + '-ui';
  const site = 'datadoghq.com';

  // only init if DD hasn't been initiated before
  // not doing this spams errors client side on every page route

  if (!window.DD_LOGS?.getInternalContext()) {
    datadogLogs.init({
      clientToken: clientToken,
      site: site,
      service: service,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      env: params.environment,
      version: params.version,
    });
  }

  if (!window.DD_RUM?.getInternalContext()) {
    datadogRum.init({
      applicationId,
      clientToken: clientToken,
      site: site,
      service: service,
      env: params.environment,
      version: params.version,
      sessionSampleRate: params.sessionSampleRate ?? 10,
      sessionReplaySampleRate: params.sessionReplaySampleRate ?? 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }

  datadogRum.startSessionReplayRecording();
};
