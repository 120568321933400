// Default to production config cat key to limit bad UX
export const DEFAULT_CONFIGCAT_KEY = 'NunaCLdRn0-FVFTn_NiPDQ/GII9vigSXUKERlH9rWkFgw';

export const handleSDKKey = (key?: string) => {
  if (!key) {
    // eslint-disable-next-line no-console
    console.warn(`no key provided using default key: ${DEFAULT_CONFIGCAT_KEY}`);
    return DEFAULT_CONFIGCAT_KEY;
  }

  return key;
};
