import { emptyHighlighterApi as api } from './api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postAftershipTrackings: build.mutation<
      PostAftershipTrackingsApiResponse,
      PostAftershipTrackingsApiArg
    >({
      query: (queryArg) => ({
        url: `/aftership/trackings`,
        method: 'POST',
        body: queryArg.aftershipEvent,
      }),
    }),
    getMyHighlighterAccounts: build.query<
      GetMyHighlighterAccountsApiResponse,
      GetMyHighlighterAccountsApiArg
    >({
      query: () => ({ url: `/my/highlighterAccounts` }),
    }),
    postMyHighlighterAccounts: build.mutation<
      PostMyHighlighterAccountsApiResponse,
      PostMyHighlighterAccountsApiArg
    >({
      query: (queryArg) => ({
        url: `/my/highlighterAccounts`,
        method: 'POST',
        body: queryArg.highlighter,
      }),
    }),
    postAdminProjectsByProjectIdBoxesBatchCreate: build.mutation<
      PostAdminProjectsByProjectIdBoxesBatchCreateApiResponse,
      PostAdminProjectsByProjectIdBoxesBatchCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/projects/${queryArg.projectId}/boxes/$batchCreate`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postAdminProjectsByProjectIdBoxesBatchCreateByKit: build.mutation<
      PostAdminProjectsByProjectIdBoxesBatchCreateByKitApiResponse,
      PostAdminProjectsByProjectIdBoxesBatchCreateByKitApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/projects/${queryArg.projectId}/boxes/$batchCreateByKit`,
        method: 'POST',
        body: queryArg.batchCreateByKitRequest,
      }),
    }),
    getAdminProjectsByProjectIdBoxes: build.query<
      GetAdminProjectsByProjectIdBoxesApiResponse,
      GetAdminProjectsByProjectIdBoxesApiArg
    >({
      query: (queryArg) => ({ url: `/admin/projects/${queryArg.projectId}/boxes` }),
    }),
    deleteAdminBoxesByBoxId: build.mutation<
      DeleteAdminBoxesByBoxIdApiResponse,
      DeleteAdminBoxesByBoxIdApiArg
    >({
      query: (queryArg) => ({ url: `/admin/boxes/${queryArg.boxId}`, method: 'DELETE' }),
    }),
    getAdminClients: build.query<GetAdminClientsApiResponse, GetAdminClientsApiArg>({
      query: () => ({ url: `/admin/Clients` }),
    }),
    getAdminEmail: build.query<GetAdminEmailApiResponse, GetAdminEmailApiArg>({
      query: () => ({ url: `/admin/Email` }),
    }),
    getHealth: build.query<GetHealthApiResponse, GetHealthApiArg>({
      query: () => ({ url: `/Health` }),
    }),
    putMyHighlighterAccounts: build.mutation<
      PutMyHighlighterAccountsApiResponse,
      PutMyHighlighterAccountsApiArg
    >({
      query: (queryArg) => ({
        url: `/my/HighlighterAccounts`,
        method: 'PUT',
        body: queryArg.highlighter,
      }),
    }),
    putMyHighlighterAccountsVerification: build.mutation<
      PutMyHighlighterAccountsVerificationApiResponse,
      PutMyHighlighterAccountsVerificationApiArg
    >({
      query: (queryArg) => ({
        url: `/my/HighlighterAccounts/verification`,
        method: 'PUT',
        body: queryArg.verifyAccountRequest,
      }),
    }),
    postMyHighlighterAccountsBirthdate: build.mutation<
      PostMyHighlighterAccountsBirthdateApiResponse,
      PostMyHighlighterAccountsBirthdateApiArg
    >({
      query: (queryArg) => ({
        url: `/my/HighlighterAccounts/birthdate`,
        method: 'POST',
        body: queryArg.updateHighlighterDateOfBirthRequest,
      }),
    }),
    postMyHighlighterAccountsActivate: build.mutation<
      PostMyHighlighterAccountsActivateApiResponse,
      PostMyHighlighterAccountsActivateApiArg
    >({
      query: () => ({ url: `/my/HighlighterAccounts/activate`, method: 'POST' }),
    }),
    putMyHighlighterAddresses: build.mutation<
      PutMyHighlighterAddressesApiResponse,
      PutMyHighlighterAddressesApiArg
    >({
      query: (queryArg) => ({
        url: `/my/HighlighterAddresses`,
        method: 'PUT',
        body: queryArg.highlighterAddress,
      }),
    }),
    postMyHighlighterAddresses: build.mutation<
      PostMyHighlighterAddressesApiResponse,
      PostMyHighlighterAddressesApiArg
    >({
      query: (queryArg) => ({
        url: `/my/HighlighterAddresses`,
        method: 'POST',
        body: queryArg.highlighterAddress,
      }),
    }),
    postMyHighlighterAddressesVerification: build.mutation<
      PostMyHighlighterAddressesVerificationApiResponse,
      PostMyHighlighterAddressesVerificationApiArg
    >({
      query: (queryArg) => ({
        url: `/my/HighlighterAddresses/verification`,
        method: 'POST',
        body: queryArg.highlighterAddress,
      }),
    }),
    putAdminHighlightersByHighlighterIdAddress: build.mutation<
      PutAdminHighlightersByHighlighterIdAddressApiResponse,
      PutAdminHighlightersByHighlighterIdAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/highlighters/${queryArg.highlighterId}/address`,
        method: 'PUT',
        body: queryArg.highlighterAddress,
      }),
    }),
    postAdminHighlightersByHighlighterIdAddress: build.mutation<
      PostAdminHighlightersByHighlighterIdAddressApiResponse,
      PostAdminHighlightersByHighlighterIdAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/highlighters/${queryArg.highlighterId}/address`,
        method: 'POST',
        body: queryArg.highlighterAddress,
      }),
    }),
    postAdminAddressesVerification: build.mutation<
      PostAdminAddressesVerificationApiResponse,
      PostAdminAddressesVerificationApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/addresses/verification`,
        method: 'POST',
        body: queryArg.highlighterAddress,
      }),
    }),
    getMyHighlighterAnswersSurveysBySurveyIdAnswers: build.query<
      GetMyHighlighterAnswersSurveysBySurveyIdAnswersApiResponse,
      GetMyHighlighterAnswersSurveysBySurveyIdAnswersApiArg
    >({
      query: (queryArg) => ({ url: `/my/HighlighterAnswers/surveys/${queryArg.surveyId}/answers` }),
    }),
    getMyHighlighterAnswers: build.query<
      GetMyHighlighterAnswersApiResponse,
      GetMyHighlighterAnswersApiArg
    >({
      query: () => ({ url: `/my/HighlighterAnswers` }),
    }),
    putMyHighlighterAnswers: build.mutation<
      PutMyHighlighterAnswersApiResponse,
      PutMyHighlighterAnswersApiArg
    >({
      query: (queryArg) => ({ url: `/my/HighlighterAnswers`, method: 'PUT', body: queryArg.body }),
    }),
    getAdminHighlightersByHighlighterIdNotes: build.query<
      GetAdminHighlightersByHighlighterIdNotesApiResponse,
      GetAdminHighlightersByHighlighterIdNotesApiArg
    >({
      query: (queryArg) => ({ url: `/admin/highlighters/${queryArg.highlighterId}/notes` }),
    }),
    postAdminHighlightersByHighlighterIdNotes: build.mutation<
      PostAdminHighlightersByHighlighterIdNotesApiResponse,
      PostAdminHighlightersByHighlighterIdNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/highlighters/${queryArg.highlighterId}/notes`,
        method: 'POST',
        body: queryArg.highlighterNote,
      }),
    }),
    postAdminNotes: build.mutation<PostAdminNotesApiResponse, PostAdminNotesApiArg>({
      query: (queryArg) => ({
        url: `/admin/notes`,
        method: 'POST',
        body: queryArg.bulkCreateNoteRequest,
      }),
    }),
    deleteAdminNotesByNoteId: build.mutation<
      DeleteAdminNotesByNoteIdApiResponse,
      DeleteAdminNotesByNoteIdApiArg
    >({
      query: (queryArg) => ({ url: `/admin/notes/${queryArg.noteId}`, method: 'DELETE' }),
    }),
    getMyHighlighterQuestions: build.query<
      GetMyHighlighterQuestionsApiResponse,
      GetMyHighlighterQuestionsApiArg
    >({
      query: () => ({ url: `/my/HighlighterQuestions` }),
    }),
    getAdminHighlighterQuestions: build.query<
      GetAdminHighlighterQuestionsApiResponse,
      GetAdminHighlighterQuestionsApiArg
    >({
      query: () => ({ url: `/admin/HighlighterQuestions` }),
    }),
    getAdminHighlightersById: build.query<
      GetAdminHighlightersByIdApiResponse,
      GetAdminHighlightersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/admin/Highlighters/${queryArg.id}` }),
    }),
    getAdminHighlighters: build.query<GetAdminHighlightersApiResponse, GetAdminHighlightersApiArg>({
      query: (queryArg) => ({
        url: `/admin/Highlighters`,
        params: {
          email: queryArg.email,
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          phone: queryArg.phone,
        },
      }),
    }),
    deleteAdminHighlighters: build.mutation<
      DeleteAdminHighlightersApiResponse,
      DeleteAdminHighlightersApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Highlighters`,
        method: 'DELETE',
        params: { email: queryArg.email },
      }),
    }),
    postAdminHighlightersBulkGet: build.mutation<
      PostAdminHighlightersBulkGetApiResponse,
      PostAdminHighlightersBulkGetApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Highlighters/$bulkGet`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getAdminHighlightersByHighlighterIdBoxes: build.query<
      GetAdminHighlightersByHighlighterIdBoxesApiResponse,
      GetAdminHighlightersByHighlighterIdBoxesApiArg
    >({
      query: (queryArg) => ({ url: `/admin/Highlighters/${queryArg.highlighterId}/boxes` }),
    }),
    patchAdminHighlightersStatus: build.mutation<
      PatchAdminHighlightersStatusApiResponse,
      PatchAdminHighlightersStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Highlighters/status`,
        method: 'PATCH',
        body: queryArg.patchBulkHighlighterStatusRequest,
      }),
    }),
    patchAdminHighlightersByHighlighterIdDateofbirth: build.mutation<
      PatchAdminHighlightersByHighlighterIdDateofbirthApiResponse,
      PatchAdminHighlightersByHighlighterIdDateofbirthApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Highlighters/${queryArg.highlighterId}/dateofbirth`,
        method: 'PATCH',
        body: queryArg.patchHighlighterDateOfBirthRequest,
      }),
    }),
    patchAdminHighlightersByHighlighterIdEmail: build.mutation<
      PatchAdminHighlightersByHighlighterIdEmailApiResponse,
      PatchAdminHighlightersByHighlighterIdEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Highlighters/${queryArg.highlighterId}/email`,
        method: 'PATCH',
        body: queryArg.patchHighlighterEmailRequest,
      }),
    }),
    getMyHighlighterSurveys: build.query<
      GetMyHighlighterSurveysApiResponse,
      GetMyHighlighterSurveysApiArg
    >({
      query: (queryArg) => ({
        url: `/my/HighlighterSurveys`,
        params: { includeCompleted: queryArg.includeCompleted, surveyType: queryArg.surveyType },
      }),
    }),
    patchMyHighlighterSurveysBySurveyInstanceId: build.mutation<
      PatchMyHighlighterSurveysBySurveyInstanceIdApiResponse,
      PatchMyHighlighterSurveysBySurveyInstanceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/my/HighlighterSurveys/${queryArg.surveyInstanceId}`,
        method: 'PATCH',
        body: queryArg.patchHighlighterSurveyRequest,
      }),
    }),
    getAdminHighlightersByHighlighterIdSurveys: build.query<
      GetAdminHighlightersByHighlighterIdSurveysApiResponse,
      GetAdminHighlightersByHighlighterIdSurveysApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/highlighters/${queryArg.highlighterId}/surveys`,
        params: { includeOptIns: queryArg.includeOptIns },
      }),
    }),
    getAdminTags: build.query<GetAdminTagsApiResponse, GetAdminTagsApiArg>({
      query: () => ({ url: `/admin/tags` }),
    }),
    getAdminHighlightersByHighlighterIdTags: build.query<
      GetAdminHighlightersByHighlighterIdTagsApiResponse,
      GetAdminHighlightersByHighlighterIdTagsApiArg
    >({
      query: (queryArg) => ({ url: `/admin/highlighters/${queryArg.highlighterId}/tags` }),
    }),
    deleteAdminHighlightersByHighlighterIdTagsAndTagId: build.mutation<
      DeleteAdminHighlightersByHighlighterIdTagsAndTagIdApiResponse,
      DeleteAdminHighlightersByHighlighterIdTagsAndTagIdApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/highlighters/${queryArg.highlighterId}/tags/${queryArg.tagId}`,
        method: 'DELETE',
      }),
    }),
    patchAdminTagsByTagId: build.mutation<
      PatchAdminTagsByTagIdApiResponse,
      PatchAdminTagsByTagIdApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/tags/${queryArg.tagId}`,
        method: 'PATCH',
        body: queryArg.bulkAssociateTagRequest,
      }),
    }),
    postMyPhoneNumbersAvailability: build.mutation<
      PostMyPhoneNumbersAvailabilityApiResponse,
      PostMyPhoneNumbersAvailabilityApiArg
    >({
      query: (queryArg) => ({
        url: `/my/PhoneNumbers/$availability`,
        method: 'POST',
        body: queryArg.phoneNumberAvailabilityRequest,
      }),
    }),
    postAuthProjectOrchestratorAuthCheck: build.mutation<
      PostAuthProjectOrchestratorAuthCheckApiResponse,
      PostAuthProjectOrchestratorAuthCheckApiArg
    >({
      query: () => ({ url: `/auth/projectOrchestrator/$authCheck`, method: 'POST' }),
    }),
    getAdminProjectsByIdSurveys: build.query<
      GetAdminProjectsByIdSurveysApiResponse,
      GetAdminProjectsByIdSurveysApiArg
    >({
      query: (queryArg) => ({ url: `/admin/Projects/${queryArg.id}/surveys` }),
    }),
    postAdminRecruitingQuery: build.mutation<
      PostAdminRecruitingQueryApiResponse,
      PostAdminRecruitingQueryApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/recruiting/$query`,
        method: 'POST',
        body: queryArg.recruitingFilter,
      }),
    }),
    getAdminRecruiting: build.query<GetAdminRecruitingApiResponse, GetAdminRecruitingApiArg>({
      query: (queryArg) => ({
        url: `/admin/recruiting`,
        params: {
          AllowHighlightEmployees: queryArg.allowHighlightEmployees,
          SendOnlyToHighlightEmployees: queryArg.sendOnlyToHighlightEmployees,
          ExcludeActiveItemSurvey: queryArg.excludeActiveItemSurvey,
          ExcludeActiveOptInSurvey: queryArg.excludeActiveOptInSurvey,
          ExcludeActiveOneOffSurvey: queryArg.excludeActiveOneOffSurvey,
          ExcludePendingItemSurvey: queryArg.excludePendingItemSurvey,
          ExcludeItemSurveyDeadlineDateTimeRangeStart:
            queryArg.excludeItemSurveyDeadlineDateTimeRangeStart,
          ExcludeItemSurveyDeadlineDateTimeRangeEnd:
            queryArg.excludeItemSurveyDeadlineDateTimeRangeEnd,
          CompletedIHUTsMin: queryArg.completedIhuTsMin,
          CompletedSurveysMin: queryArg.completedSurveysMin,
          CompletedSurveysMax: queryArg.completedSurveysMax,
          DontUseForBRS: queryArg.dontUseForBrs,
          DontUseForIDIs: queryArg.dontUseForIdIs,
          DontUseForPhotos: queryArg.dontUseForPhotos,
          DontUseForProductReturns: queryArg.dontUseForProductReturns,
          DontUseForVideos: queryArg.dontUseForVideos,
          HighlighterIds: queryArg.highlighterIds,
          gender: queryArg.gender,
          employment_status: queryArg.employmentStatus,
          employment_industry: queryArg.employmentIndustry,
          household_income: queryArg.householdIncome,
          ethnicity: queryArg.ethnicity,
          food_restrictions: queryArg.foodRestrictions,
          workout_frequency: queryArg.workoutFrequency,
          relationship_status: queryArg.relationshipStatus,
          sneaker_brands: queryArg.sneakerBrands,
          water_brands: queryArg.waterBrands,
          tech_savviness: queryArg.techSavviness,
          shopping_preferences: queryArg.shoppingPreferences,
          hearst_audiences: queryArg.hearstAudiences,
          extra_tasks: queryArg.extraTasks,
          reminders_type: queryArg.remindersType,
          education: queryArg.education,
          parental_status: queryArg.parentalStatus,
          purchasing_power: queryArg.purchasingPower,
          online_shopping: queryArg.onlineShopping,
          grocery_shopping: queryArg.groceryShopping,
          big_box_shopping: queryArg.bigBoxShopping,
          convenience_shopping: queryArg.convenienceShopping,
          better_for_you: queryArg.betterForYou,
          plant_forwardness: queryArg.plantForwardness,
          allergy: queryArg.allergy,
          grocery_shopped: queryArg.groceryShopped,
          ageMin: queryArg.ageMin,
          ageMax: queryArg.ageMax,
          Regions: queryArg.regions,
          States: queryArg.states,
        },
      }),
    }),
    getAdminRecruitingCount: build.query<
      GetAdminRecruitingCountApiResponse,
      GetAdminRecruitingCountApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/recruiting/count`,
        params: {
          AllowHighlightEmployees: queryArg.allowHighlightEmployees,
          SendOnlyToHighlightEmployees: queryArg.sendOnlyToHighlightEmployees,
          ExcludeActiveItemSurvey: queryArg.excludeActiveItemSurvey,
          ExcludeActiveOptInSurvey: queryArg.excludeActiveOptInSurvey,
          ExcludeActiveOneOffSurvey: queryArg.excludeActiveOneOffSurvey,
          ExcludePendingItemSurvey: queryArg.excludePendingItemSurvey,
          ExcludeItemSurveyDeadlineDateTimeRangeStart:
            queryArg.excludeItemSurveyDeadlineDateTimeRangeStart,
          ExcludeItemSurveyDeadlineDateTimeRangeEnd:
            queryArg.excludeItemSurveyDeadlineDateTimeRangeEnd,
          CompletedIHUTsMin: queryArg.completedIhuTsMin,
          CompletedSurveysMin: queryArg.completedSurveysMin,
          CompletedSurveysMax: queryArg.completedSurveysMax,
          DontUseForBRS: queryArg.dontUseForBrs,
          DontUseForIDIs: queryArg.dontUseForIdIs,
          DontUseForPhotos: queryArg.dontUseForPhotos,
          DontUseForProductReturns: queryArg.dontUseForProductReturns,
          DontUseForVideos: queryArg.dontUseForVideos,
          HighlighterIds: queryArg.highlighterIds,
          gender: queryArg.gender,
          employment_status: queryArg.employmentStatus,
          employment_industry: queryArg.employmentIndustry,
          household_income: queryArg.householdIncome,
          ethnicity: queryArg.ethnicity,
          food_restrictions: queryArg.foodRestrictions,
          workout_frequency: queryArg.workoutFrequency,
          relationship_status: queryArg.relationshipStatus,
          sneaker_brands: queryArg.sneakerBrands,
          water_brands: queryArg.waterBrands,
          tech_savviness: queryArg.techSavviness,
          shopping_preferences: queryArg.shoppingPreferences,
          hearst_audiences: queryArg.hearstAudiences,
          extra_tasks: queryArg.extraTasks,
          reminders_type: queryArg.remindersType,
          education: queryArg.education,
          parental_status: queryArg.parentalStatus,
          purchasing_power: queryArg.purchasingPower,
          online_shopping: queryArg.onlineShopping,
          grocery_shopping: queryArg.groceryShopping,
          big_box_shopping: queryArg.bigBoxShopping,
          convenience_shopping: queryArg.convenienceShopping,
          better_for_you: queryArg.betterForYou,
          plant_forwardness: queryArg.plantForwardness,
          allergy: queryArg.allergy,
          grocery_shopped: queryArg.groceryShopped,
          ageMin: queryArg.ageMin,
          ageMax: queryArg.ageMax,
          Regions: queryArg.regions,
          States: queryArg.states,
        },
      }),
    }),
    postAdminRecruitingFilter: build.mutation<
      PostAdminRecruitingFilterApiResponse,
      PostAdminRecruitingFilterApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/recruiting/filter`,
        method: 'POST',
        body: queryArg.filterHighlightersRequest,
      }),
    }),
    getAdminRecruitingByProjectIdCompletedRecruits: build.query<
      GetAdminRecruitingByProjectIdCompletedRecruitsApiResponse,
      GetAdminRecruitingByProjectIdCompletedRecruitsApiArg
    >({
      query: (queryArg) => ({ url: `/admin/recruiting/${queryArg.projectId}/completedRecruits` }),
    }),
    getAdminRecruitingSurveysBySurveyIdProfiles: build.query<
      GetAdminRecruitingSurveysBySurveyIdProfilesApiResponse,
      GetAdminRecruitingSurveysBySurveyIdProfilesApiArg
    >({
      query: (queryArg) => ({ url: `/admin/recruiting/surveys/${queryArg.surveyId}/profiles` }),
    }),
    getAdminRecruitingSurveysBySurveyIdHighlighters: build.query<
      GetAdminRecruitingSurveysBySurveyIdHighlightersApiResponse,
      GetAdminRecruitingSurveysBySurveyIdHighlightersApiArg
    >({
      query: (queryArg) => ({ url: `/admin/recruiting/surveys/${queryArg.surveyId}/highlighters` }),
    }),
    getAdminReferralCodes: build.query<
      GetAdminReferralCodesApiResponse,
      GetAdminReferralCodesApiArg
    >({
      query: () => ({ url: `/admin/ReferralCodes` }),
    }),
    postAdminReferralCodes: build.mutation<
      PostAdminReferralCodesApiResponse,
      PostAdminReferralCodesApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/ReferralCodes`,
        method: 'POST',
        body: queryArg.referralCode,
      }),
    }),
    getAdminReferralCodesById: build.query<
      GetAdminReferralCodesByIdApiResponse,
      GetAdminReferralCodesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/admin/ReferralCodes/${queryArg.id}` }),
    }),
    putAdminReferralCodesByReferralCodeId: build.mutation<
      PutAdminReferralCodesByReferralCodeIdApiResponse,
      PutAdminReferralCodesByReferralCodeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/ReferralCodes/${queryArg.referralCodeId}`,
        method: 'PUT',
        body: queryArg.referralCode,
      }),
    }),
    getReferralCodesValidateByCode: build.query<
      GetReferralCodesValidateByCodeApiResponse,
      GetReferralCodesValidateByCodeApiArg
    >({
      query: (queryArg) => ({ url: `/ReferralCodes/validate/${queryArg.code}` }),
    }),
    postAdminSurveysBySurveyIdSurveyInstances: build.mutation<
      PostAdminSurveysBySurveyIdSurveyInstancesApiResponse,
      PostAdminSurveysBySurveyIdSurveyInstancesApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Surveys/${queryArg.surveyId}/SurveyInstances`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getAdminSurveysBySurveyIdSurveyInstances: build.query<
      GetAdminSurveysBySurveyIdSurveyInstancesApiResponse,
      GetAdminSurveysBySurveyIdSurveyInstancesApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Surveys/${queryArg.surveyId}/SurveyInstances`,
        params: {
          AllowHighlightEmployees: queryArg.allowHighlightEmployees,
          SendOnlyToHighlightEmployees: queryArg.sendOnlyToHighlightEmployees,
          ExcludeActiveItemSurvey: queryArg.excludeActiveItemSurvey,
          ExcludeActiveOptInSurvey: queryArg.excludeActiveOptInSurvey,
          ExcludeActiveOneOffSurvey: queryArg.excludeActiveOneOffSurvey,
          ExcludePendingItemSurvey: queryArg.excludePendingItemSurvey,
          ExcludeItemSurveyDeadlineDateTimeRangeStart:
            queryArg.excludeItemSurveyDeadlineDateTimeRangeStart,
          ExcludeItemSurveyDeadlineDateTimeRangeEnd:
            queryArg.excludeItemSurveyDeadlineDateTimeRangeEnd,
          CompletedIHUTsMin: queryArg.completedIhuTsMin,
          CompletedSurveysMin: queryArg.completedSurveysMin,
          CompletedSurveysMax: queryArg.completedSurveysMax,
          DontUseForBRS: queryArg.dontUseForBrs,
          DontUseForIDIs: queryArg.dontUseForIdIs,
          DontUseForPhotos: queryArg.dontUseForPhotos,
          DontUseForProductReturns: queryArg.dontUseForProductReturns,
          DontUseForVideos: queryArg.dontUseForVideos,
          HighlighterIds: queryArg.highlighterIds,
          gender: queryArg.gender,
          employment_status: queryArg.employmentStatus,
          employment_industry: queryArg.employmentIndustry,
          household_income: queryArg.householdIncome,
          ethnicity: queryArg.ethnicity,
          food_restrictions: queryArg.foodRestrictions,
          workout_frequency: queryArg.workoutFrequency,
          relationship_status: queryArg.relationshipStatus,
          sneaker_brands: queryArg.sneakerBrands,
          water_brands: queryArg.waterBrands,
          tech_savviness: queryArg.techSavviness,
          shopping_preferences: queryArg.shoppingPreferences,
          hearst_audiences: queryArg.hearstAudiences,
          extra_tasks: queryArg.extraTasks,
          reminders_type: queryArg.remindersType,
          education: queryArg.education,
          parental_status: queryArg.parentalStatus,
          purchasing_power: queryArg.purchasingPower,
          online_shopping: queryArg.onlineShopping,
          grocery_shopping: queryArg.groceryShopping,
          big_box_shopping: queryArg.bigBoxShopping,
          convenience_shopping: queryArg.convenienceShopping,
          better_for_you: queryArg.betterForYou,
          plant_forwardness: queryArg.plantForwardness,
          allergy: queryArg.allergy,
          grocery_shopped: queryArg.groceryShopped,
          ageMin: queryArg.ageMin,
          ageMax: queryArg.ageMax,
          Regions: queryArg.regions,
          States: queryArg.states,
        },
      }),
    }),
    getAdminSurveysBySurveyIdAllSurveyInstances: build.query<
      GetAdminSurveysBySurveyIdAllSurveyInstancesApiResponse,
      GetAdminSurveysBySurveyIdAllSurveyInstancesApiArg
    >({
      query: (queryArg) => ({ url: `/admin/Surveys/${queryArg.surveyId}/AllSurveyInstances` }),
    }),
    patchAdminSurveyInstancesBySurveyInstanceId: build.mutation<
      PatchAdminSurveyInstancesBySurveyInstanceIdApiResponse,
      PatchAdminSurveyInstancesBySurveyInstanceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/SurveyInstances/${queryArg.surveyInstanceId}`,
        method: 'PATCH',
        body: queryArg.patchSurveyInstanceRequest,
      }),
    }),
    getAdminSurveyInstancesBySurveyInstanceId: build.query<
      GetAdminSurveyInstancesBySurveyInstanceIdApiResponse,
      GetAdminSurveyInstancesBySurveyInstanceIdApiArg
    >({
      query: (queryArg) => ({ url: `/admin/SurveyInstances/${queryArg.surveyInstanceId}` }),
    }),
    postAdminSurveys: build.mutation<PostAdminSurveysApiResponse, PostAdminSurveysApiArg>({
      query: (queryArg) => ({ url: `/admin/Surveys`, method: 'POST', body: queryArg.survey }),
    }),
    getAdminSurveys: build.query<GetAdminSurveysApiResponse, GetAdminSurveysApiArg>({
      query: () => ({ url: `/admin/Surveys` }),
    }),
    putAdminSurveysById: build.mutation<PutAdminSurveysByIdApiResponse, PutAdminSurveysByIdApiArg>({
      query: (queryArg) => ({
        url: `/admin/Surveys/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.survey,
      }),
    }),
    getAdminSurveysById: build.query<GetAdminSurveysByIdApiResponse, GetAdminSurveysByIdApiArg>({
      query: (queryArg) => ({ url: `/admin/Surveys/${queryArg.id}` }),
    }),
    putAdminSurveysBySurveyIdActions: build.mutation<
      PutAdminSurveysBySurveyIdActionsApiResponse,
      PutAdminSurveysBySurveyIdActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Surveys/${queryArg.surveyId}/actions`,
        method: 'PUT',
        body: queryArg.surveyActionRequest,
      }),
    }),
    postAdminSurveysBySurveyIdFill: build.mutation<
      PostAdminSurveysBySurveyIdFillApiResponse,
      PostAdminSurveysBySurveyIdFillApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Surveys/${queryArg.surveyId}/$fill`,
        method: 'POST',
        body: queryArg.fillSurveyRequest,
      }),
    }),
    postAdminSurveysByProfileSurveyIdSendprofilesurvey: build.mutation<
      PostAdminSurveysByProfileSurveyIdSendprofilesurveyApiResponse,
      PostAdminSurveysByProfileSurveyIdSendprofilesurveyApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Surveys/${queryArg.profileSurveyId}/$sendprofilesurvey`,
        method: 'POST',
      }),
    }),
    postAdminSurveysBySurveyIdFillpartitioned: build.mutation<
      PostAdminSurveysBySurveyIdFillpartitionedApiResponse,
      PostAdminSurveysBySurveyIdFillpartitionedApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Surveys/${queryArg.surveyId}/$fillpartitioned`,
        method: 'POST',
        body: queryArg.fillPartitionedRequest,
      }),
    }),
    postAdminSurveysBySurveyIdNudge: build.mutation<
      PostAdminSurveysBySurveyIdNudgeApiResponse,
      PostAdminSurveysBySurveyIdNudgeApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Surveys/${queryArg.surveyId}/$nudge`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postAdminSurveysBySurveyIdFailedQc: build.mutation<
      PostAdminSurveysBySurveyIdFailedQcApiResponse,
      PostAdminSurveysBySurveyIdFailedQcApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/Surveys/${queryArg.surveyId}/$failedQC`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postAuthToken: build.mutation<PostAuthTokenApiResponse, PostAuthTokenApiArg>({
      query: (queryArg) => ({ url: `/auth/token`, method: 'POST', body: queryArg.tokenRequest }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as highlighterApi };
export type PostAftershipTrackingsApiResponse = unknown;
export type PostAftershipTrackingsApiArg = {
  aftershipEvent: AftershipEvent;
};
export type GetMyHighlighterAccountsApiResponse = /** status 200 Success */ Highlighter;
export type GetMyHighlighterAccountsApiArg = void;
export type PostMyHighlighterAccountsApiResponse = /** status 200 Success */ Highlighter;
export type PostMyHighlighterAccountsApiArg = {
  highlighter: Highlighter;
};
export type PostAdminProjectsByProjectIdBoxesBatchCreateApiResponse =
  /** status 200 Success */ Box[];
export type PostAdminProjectsByProjectIdBoxesBatchCreateApiArg = {
  /** The Client Portal's Project ID */
  projectId: number;
  /** The list of Highlighter IDs to create boxes for */
  body: string[];
};
export type PostAdminProjectsByProjectIdBoxesBatchCreateByKitApiResponse =
  /** status 200 Success */ Box[];
export type PostAdminProjectsByProjectIdBoxesBatchCreateByKitApiArg = {
  /** The Client Portal's Project ID */
  projectId: number;
  /** Contains the list of Highlighter IDs and Kit ID to create boxes for */
  batchCreateByKitRequest: BatchCreateByKitRequest;
};
export type GetAdminProjectsByProjectIdBoxesApiResponse = /** status 200 Success */ Box[];
export type GetAdminProjectsByProjectIdBoxesApiArg = {
  /** The Client Portal's Project Id (client.client_portal_id in this db) */
  projectId: number;
};
export type DeleteAdminBoxesByBoxIdApiResponse = unknown;
export type DeleteAdminBoxesByBoxIdApiArg = {
  /** The ID of a Box without a tracking number */
  boxId: string;
};
export type GetAdminClientsApiResponse = /** status 200 Success */ Client[];
export type GetAdminClientsApiArg = void;
export type GetAdminEmailApiResponse = /** status 200 Success */ EmailTemplate[];
export type GetAdminEmailApiArg = void;
export type GetHealthApiResponse = /** status 200 Success */ string;
export type GetHealthApiArg = void;
export type PutMyHighlighterAccountsApiResponse = unknown;
export type PutMyHighlighterAccountsApiArg = {
  highlighter: Highlighter;
};
export type PutMyHighlighterAccountsVerificationApiResponse = /** status 200 Success */ Highlighter;
export type PutMyHighlighterAccountsVerificationApiArg = {
  verifyAccountRequest: VerifyAccountRequest;
};
export type PostMyHighlighterAccountsBirthdateApiResponse = /** status 200 Success */ string;
export type PostMyHighlighterAccountsBirthdateApiArg = {
  updateHighlighterDateOfBirthRequest: UpdateHighlighterDateOfBirthRequest;
};
export type PostMyHighlighterAccountsActivateApiResponse = /** status 200 Success */ Highlighter;
export type PostMyHighlighterAccountsActivateApiArg = void;
export type PutMyHighlighterAddressesApiResponse = unknown;
export type PutMyHighlighterAddressesApiArg = {
  highlighterAddress: HighlighterAddress;
};
export type PostMyHighlighterAddressesApiResponse = unknown;
export type PostMyHighlighterAddressesApiArg = {
  highlighterAddress: HighlighterAddress;
};
export type PostMyHighlighterAddressesVerificationApiResponse =
  /** status 200 Success */ SmartyStreetsAddressWithValidationRead;
export type PostMyHighlighterAddressesVerificationApiArg = {
  highlighterAddress: HighlighterAddress;
};
export type PutAdminHighlightersByHighlighterIdAddressApiResponse = unknown;
export type PutAdminHighlightersByHighlighterIdAddressApiArg = {
  highlighterId: string;
  highlighterAddress: HighlighterAddress;
};
export type PostAdminHighlightersByHighlighterIdAddressApiResponse = unknown;
export type PostAdminHighlightersByHighlighterIdAddressApiArg = {
  highlighterId: string;
  highlighterAddress: HighlighterAddress;
};
export type PostAdminAddressesVerificationApiResponse =
  /** status 200 Success */ SmartyStreetsAddressWithValidationRead;
export type PostAdminAddressesVerificationApiArg = {
  highlighterAddress: HighlighterAddress;
};
export type GetMyHighlighterAnswersSurveysBySurveyIdAnswersApiResponse =
  /** status 200 Success */ ProfileAnswers[];
export type GetMyHighlighterAnswersSurveysBySurveyIdAnswersApiArg = {
  surveyId: string;
};
export type GetMyHighlighterAnswersApiResponse = /** status 200 Success */ HighlighterAnswer[];
export type GetMyHighlighterAnswersApiArg = void;
export type PutMyHighlighterAnswersApiResponse = /** status 200 Success */ HighlighterAnswer[];
export type PutMyHighlighterAnswersApiArg = {
  body: HighlighterAnswer[];
};
export type GetAdminHighlightersByHighlighterIdNotesApiResponse =
  /** status 200 Success */ HighlighterNote[];
export type GetAdminHighlightersByHighlighterIdNotesApiArg = {
  /** The ID of the highlighter. */
  highlighterId: string;
};
export type PostAdminHighlightersByHighlighterIdNotesApiResponse =
  /** status 200 Success */ HighlighterNote;
export type PostAdminHighlightersByHighlighterIdNotesApiArg = {
  highlighterId: string;
  highlighterNote: HighlighterNote;
};
export type PostAdminNotesApiResponse = /** status 200 Success */ BulkCreateNoteResponse;
export type PostAdminNotesApiArg = {
  bulkCreateNoteRequest: BulkCreateNoteRequest;
};
export type DeleteAdminNotesByNoteIdApiResponse = unknown;
export type DeleteAdminNotesByNoteIdApiArg = {
  noteId: string;
};
export type GetMyHighlighterQuestionsApiResponse = /** status 200 Success */ HighlighterQuestion[];
export type GetMyHighlighterQuestionsApiArg = void;
export type GetAdminHighlighterQuestionsApiResponse =
  /** status 200 Success */ HighlighterQuestion[];
export type GetAdminHighlighterQuestionsApiArg = void;
export type GetAdminHighlightersByIdApiResponse = /** status 200 Success */ Highlighter;
export type GetAdminHighlightersByIdApiArg = {
  /** The ID of the highlighter to retrieve. */
  id: string;
};
export type GetAdminHighlightersApiResponse = /** status 200 Success */ Highlighter[];
export type GetAdminHighlightersApiArg = {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
};
export type DeleteAdminHighlightersApiResponse = /** status 200 Success */ Highlighter;
export type DeleteAdminHighlightersApiArg = {
  email?: string;
};
export type PostAdminHighlightersBulkGetApiResponse = /** status 200 Success */ Highlighter[];
export type PostAdminHighlightersBulkGetApiArg = {
  /** The ID of the highlighter to retrieve. */
  body: string[];
};
export type GetAdminHighlightersByHighlighterIdBoxesApiResponse = /** status 200 Success */ Box[];
export type GetAdminHighlightersByHighlighterIdBoxesApiArg = {
  highlighterId: string;
};
export type PatchAdminHighlightersStatusApiResponse = /** status 200 Success */ number;
export type PatchAdminHighlightersStatusApiArg = {
  patchBulkHighlighterStatusRequest: PatchBulkHighlighterStatusRequest;
};
export type PatchAdminHighlightersByHighlighterIdDateofbirthApiResponse =
  /** status 200 Success */ Highlighter;
export type PatchAdminHighlightersByHighlighterIdDateofbirthApiArg = {
  highlighterId: string;
  patchHighlighterDateOfBirthRequest: PatchHighlighterDateOfBirthRequest;
};
export type PatchAdminHighlightersByHighlighterIdEmailApiResponse =
  /** status 200 Success */ Highlighter;
export type PatchAdminHighlightersByHighlighterIdEmailApiArg = {
  highlighterId: string;
  patchHighlighterEmailRequest: PatchHighlighterEmailRequest;
};
export type GetMyHighlighterSurveysApiResponse = /** status 200 Success */ HighlighterSurveyRead[];
export type GetMyHighlighterSurveysApiArg = {
  includeCompleted?: boolean;
  surveyType?: SurveyType;
};
export type PatchMyHighlighterSurveysBySurveyInstanceIdApiResponse = unknown;
export type PatchMyHighlighterSurveysBySurveyInstanceIdApiArg = {
  surveyInstanceId: string;
  patchHighlighterSurveyRequest: PatchHighlighterSurveyRequest;
};
export type GetAdminHighlightersByHighlighterIdSurveysApiResponse =
  /** status 200 Success */ HighlighterSurveyAdminRead[];
export type GetAdminHighlightersByHighlighterIdSurveysApiArg = {
  /** The ID of the highlighter. */
  highlighterId: string;
  /** To include opt ins */
  includeOptIns?: boolean;
};
export type GetAdminTagsApiResponse = /** status 200 Success */ HighlighterTag[];
export type GetAdminTagsApiArg = void;
export type GetAdminHighlightersByHighlighterIdTagsApiResponse =
  /** status 200 Success */ HighlighterTag[];
export type GetAdminHighlightersByHighlighterIdTagsApiArg = {
  /** The ID of the highlighter */
  highlighterId: string;
};
export type DeleteAdminHighlightersByHighlighterIdTagsAndTagIdApiResponse = unknown;
export type DeleteAdminHighlightersByHighlighterIdTagsAndTagIdApiArg = {
  highlighterId: string;
  tagId: string;
};
export type PatchAdminTagsByTagIdApiResponse = /** status 200 Success */ BulkAssociateTagResponse;
export type PatchAdminTagsByTagIdApiArg = {
  tagId: string;
  bulkAssociateTagRequest: BulkAssociateTagRequest;
};
export type PostMyPhoneNumbersAvailabilityApiResponse =
  /** status 200 Success */ PhoneNumberAvailabilityResult;
export type PostMyPhoneNumbersAvailabilityApiArg = {
  phoneNumberAvailabilityRequest: PhoneNumberAvailabilityRequest;
};
export type PostAuthProjectOrchestratorAuthCheckApiResponse = unknown;
export type PostAuthProjectOrchestratorAuthCheckApiArg = void;
export type GetAdminProjectsByIdSurveysApiResponse = /** status 200 Success */ Survey[];
export type GetAdminProjectsByIdSurveysApiArg = {
  id: number;
};
export type PostAdminRecruitingQueryApiResponse = /** status 200 Success */ Recruit[];
export type PostAdminRecruitingQueryApiArg = {
  recruitingFilter: RecruitingFilter;
};
export type GetAdminRecruitingApiResponse = /** status 200 Success */ Recruit[];
export type GetAdminRecruitingApiArg = {
  allowHighlightEmployees?: boolean;
  sendOnlyToHighlightEmployees?: boolean;
  excludeActiveItemSurvey?: boolean;
  excludeActiveOptInSurvey?: boolean;
  excludeActiveOneOffSurvey?: boolean;
  excludePendingItemSurvey?: boolean;
  excludeItemSurveyDeadlineDateTimeRangeStart?: string;
  excludeItemSurveyDeadlineDateTimeRangeEnd?: string;
  completedIhuTsMin?: number;
  completedSurveysMin?: number;
  completedSurveysMax?: number;
  dontUseForBrs?: boolean;
  dontUseForIdIs?: boolean;
  dontUseForPhotos?: boolean;
  dontUseForProductReturns?: boolean;
  dontUseForVideos?: boolean;
  highlighterIds?: string[];
  gender?: string;
  employmentStatus?: string;
  employmentIndustry?: string;
  householdIncome?: string;
  ethnicity?: string;
  foodRestrictions?: string;
  workoutFrequency?: string;
  relationshipStatus?: string;
  sneakerBrands?: string;
  waterBrands?: string;
  techSavviness?: string;
  shoppingPreferences?: string;
  hearstAudiences?: string;
  extraTasks?: string;
  remindersType?: string;
  education?: string;
  parentalStatus?: string;
  purchasingPower?: string;
  onlineShopping?: string;
  groceryShopping?: string;
  bigBoxShopping?: string;
  convenienceShopping?: string;
  betterForYou?: string;
  plantForwardness?: string;
  allergy?: string;
  groceryShopped?: string;
  ageMin?: number;
  ageMax?: number;
  regions?: string;
  states?: string;
};
export type GetAdminRecruitingCountApiResponse = /** status 200 Success */ RecruitCount;
export type GetAdminRecruitingCountApiArg = {
  allowHighlightEmployees?: boolean;
  sendOnlyToHighlightEmployees?: boolean;
  excludeActiveItemSurvey?: boolean;
  excludeActiveOptInSurvey?: boolean;
  excludeActiveOneOffSurvey?: boolean;
  excludePendingItemSurvey?: boolean;
  excludeItemSurveyDeadlineDateTimeRangeStart?: string;
  excludeItemSurveyDeadlineDateTimeRangeEnd?: string;
  completedIhuTsMin?: number;
  completedSurveysMin?: number;
  completedSurveysMax?: number;
  dontUseForBrs?: boolean;
  dontUseForIdIs?: boolean;
  dontUseForPhotos?: boolean;
  dontUseForProductReturns?: boolean;
  dontUseForVideos?: boolean;
  highlighterIds?: string[];
  gender?: string;
  employmentStatus?: string;
  employmentIndustry?: string;
  householdIncome?: string;
  ethnicity?: string;
  foodRestrictions?: string;
  workoutFrequency?: string;
  relationshipStatus?: string;
  sneakerBrands?: string;
  waterBrands?: string;
  techSavviness?: string;
  shoppingPreferences?: string;
  hearstAudiences?: string;
  extraTasks?: string;
  remindersType?: string;
  education?: string;
  parentalStatus?: string;
  purchasingPower?: string;
  onlineShopping?: string;
  groceryShopping?: string;
  bigBoxShopping?: string;
  convenienceShopping?: string;
  betterForYou?: string;
  plantForwardness?: string;
  allergy?: string;
  groceryShopped?: string;
  ageMin?: number;
  ageMax?: number;
  regions?: string;
  states?: string;
};
export type PostAdminRecruitingFilterApiResponse = /** status 200 Success */ string[];
export type PostAdminRecruitingFilterApiArg = {
  filterHighlightersRequest: FilterHighlightersRequest;
};
export type GetAdminRecruitingByProjectIdCompletedRecruitsApiResponse =
  /** status 200 Success */ RecruitCount;
export type GetAdminRecruitingByProjectIdCompletedRecruitsApiArg = {
  projectId: number;
};
export type GetAdminRecruitingSurveysBySurveyIdProfilesApiResponse =
  /** status 200 Success */ HighlighterProfile[];
export type GetAdminRecruitingSurveysBySurveyIdProfilesApiArg = {
  surveyId: string;
};
export type GetAdminRecruitingSurveysBySurveyIdHighlightersApiResponse =
  /** status 200 Success */ HighlighterForOptIn[];
export type GetAdminRecruitingSurveysBySurveyIdHighlightersApiArg = {
  surveyId: string;
};
export type GetAdminReferralCodesApiResponse = /** status 200 Success */ ReferralCode[];
export type GetAdminReferralCodesApiArg = void;
export type PostAdminReferralCodesApiResponse = /** status 200 Success */ ReferralCode;
export type PostAdminReferralCodesApiArg = {
  referralCode: ReferralCode;
};
export type GetAdminReferralCodesByIdApiResponse = /** status 200 Success */ ReferralCode;
export type GetAdminReferralCodesByIdApiArg = {
  id: string;
};
export type PutAdminReferralCodesByReferralCodeIdApiResponse =
  /** status 200 Success */ ReferralCode;
export type PutAdminReferralCodesByReferralCodeIdApiArg = {
  referralCodeId: string;
  referralCode: ReferralCode;
};
export type GetReferralCodesValidateByCodeApiResponse =
  /** status 200 Success */ ReferralCodeValidationResult;
export type GetReferralCodesValidateByCodeApiArg = {
  code: string;
};
export type PostAdminSurveysBySurveyIdSurveyInstancesApiResponse =
  /** status 200 Success */ CreateSurveyInstanceResult;
export type PostAdminSurveysBySurveyIdSurveyInstancesApiArg = {
  surveyId: string;
  body: {
    ContentType?: string;
    ContentDisposition?: string;
    Headers?: {
      [key: string]: string[];
    };
    Length?: number;
    Name?: string;
    FileName?: string;
  };
};
export type GetAdminSurveysBySurveyIdSurveyInstancesApiResponse =
  /** status 200 Success */ SurveyInstanceRead[];
export type GetAdminSurveysBySurveyIdSurveyInstancesApiArg = {
  surveyId: string;
  allowHighlightEmployees?: boolean;
  sendOnlyToHighlightEmployees?: boolean;
  excludeActiveItemSurvey?: boolean;
  excludeActiveOptInSurvey?: boolean;
  excludeActiveOneOffSurvey?: boolean;
  excludePendingItemSurvey?: boolean;
  excludeItemSurveyDeadlineDateTimeRangeStart?: string;
  excludeItemSurveyDeadlineDateTimeRangeEnd?: string;
  completedIhuTsMin?: number;
  completedSurveysMin?: number;
  completedSurveysMax?: number;
  dontUseForBrs?: boolean;
  dontUseForIdIs?: boolean;
  dontUseForPhotos?: boolean;
  dontUseForProductReturns?: boolean;
  dontUseForVideos?: boolean;
  highlighterIds?: string[];
  gender?: string;
  employmentStatus?: string;
  employmentIndustry?: string;
  householdIncome?: string;
  ethnicity?: string;
  foodRestrictions?: string;
  workoutFrequency?: string;
  relationshipStatus?: string;
  sneakerBrands?: string;
  waterBrands?: string;
  techSavviness?: string;
  shoppingPreferences?: string;
  hearstAudiences?: string;
  extraTasks?: string;
  remindersType?: string;
  education?: string;
  parentalStatus?: string;
  purchasingPower?: string;
  onlineShopping?: string;
  groceryShopping?: string;
  bigBoxShopping?: string;
  convenienceShopping?: string;
  betterForYou?: string;
  plantForwardness?: string;
  allergy?: string;
  groceryShopped?: string;
  ageMin?: number;
  ageMax?: number;
  regions?: string;
  states?: string;
};
export type GetAdminSurveysBySurveyIdAllSurveyInstancesApiResponse =
  /** status 200 Success */ SurveyInstanceRead[];
export type GetAdminSurveysBySurveyIdAllSurveyInstancesApiArg = {
  surveyId: string;
};
export type PatchAdminSurveyInstancesBySurveyInstanceIdApiResponse =
  /** status 200 Success */ SurveyInstanceRead;
export type PatchAdminSurveyInstancesBySurveyInstanceIdApiArg = {
  surveyInstanceId: string;
  patchSurveyInstanceRequest: PatchSurveyInstanceRequest;
};
export type GetAdminSurveyInstancesBySurveyInstanceIdApiResponse =
  /** status 200 Success */ SurveyInstanceRead;
export type GetAdminSurveyInstancesBySurveyInstanceIdApiArg = {
  surveyInstanceId: string;
};
export type PostAdminSurveysApiResponse = /** status 200 Success */ Survey;
export type PostAdminSurveysApiArg = {
  survey: Survey;
};
export type GetAdminSurveysApiResponse = /** status 200 Success */ Survey[];
export type GetAdminSurveysApiArg = void;
export type PutAdminSurveysByIdApiResponse = /** status 200 Success */ Survey;
export type PutAdminSurveysByIdApiArg = {
  id: string;
  survey: Survey;
};
export type GetAdminSurveysByIdApiResponse = /** status 200 Success */ Survey;
export type GetAdminSurveysByIdApiArg = {
  /** The Id of the survey to retrieve. */
  id: string;
};
export type PutAdminSurveysBySurveyIdActionsApiResponse =
  /** status 200 Success */ SurveyInstanceRead[];
export type PutAdminSurveysBySurveyIdActionsApiArg = {
  surveyId: string;
  surveyActionRequest: SurveyActionRequest;
};
export type PostAdminSurveysBySurveyIdFillApiResponse =
  /** status 200 Success */ CreateSurveyInstanceResult;
export type PostAdminSurveysBySurveyIdFillApiArg = {
  surveyId: string;
  fillSurveyRequest: FillSurveyRequest;
};
export type PostAdminSurveysByProfileSurveyIdSendprofilesurveyApiResponse =
  /** status 200 Success */ CreateSurveyInstanceResult;
export type PostAdminSurveysByProfileSurveyIdSendprofilesurveyApiArg = {
  profileSurveyId: string;
};
export type PostAdminSurveysBySurveyIdFillpartitionedApiResponse =
  /** status 200 Success */ FillOptInSurveyResult;
export type PostAdminSurveysBySurveyIdFillpartitionedApiArg = {
  surveyId: string;
  fillPartitionedRequest: FillPartitionedRequest;
};
export type PostAdminSurveysBySurveyIdNudgeApiResponse = /** status 200 Success */ string[];
export type PostAdminSurveysBySurveyIdNudgeApiArg = {
  surveyId: string;
  body: string[];
};
export type PostAdminSurveysBySurveyIdFailedQcApiResponse =
  /** status 200 Success */ SurveyInstanceRead[];
export type PostAdminSurveysBySurveyIdFailedQcApiArg = {
  surveyId: string;
  body: string[];
};
export type PostAuthTokenApiResponse = /** status 200 Success */ TokenResponseRead;
export type PostAuthTokenApiArg = {
  tokenRequest: TokenRequest;
};
export type AftershipDeliveryStatus =
  | 'NotAssigned'
  | 'InfoReceived'
  | 'InTransit'
  | 'OutForDelivery'
  | 'AttemptFail'
  | 'Delivered'
  | 'AvailableForPickup'
  | 'Exception'
  | 'Expired'
  | 'Pending';
export type AftershipEventDetails = {
  id?: string | null;
  tracking_number?: string | null;
  order_id?: string | null;
  tag?: AftershipDeliveryStatus;
  slug?: string | null;
  last_updated_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  expected_delivery?: string | null;
};
export type AftershipEvent = {
  ts?: number;
  event?: string | null;
  event_id?: string;
  msg?: AftershipEventDetails;
};
export type HighlighterAddress = {
  id?: string | null;
  country?: string | null;
  city?: string | null;
  lineThree?: string | null;
  lineTwo?: string | null;
  lineOne?: string | null;
  postalCode?: string | null;
  /** Region is a calcuated field set in the database.
    Its based on the Highlighter's State. */
  region?: string | null;
  state?: string | null;
  createdAt?: string;
  updatedAt?: string | null;
};
export type HighlighterStatus = 'active' | 'banned' | 'deleted' | 'disabled' | 'new';
export type Highlighter = {
  address?: HighlighterAddress;
  createdAt?: string;
  dateOfBirth?: string | null;
  deletedAt?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  id?: string | null;
  phone?: string | null;
  phoneVerified?: boolean;
  signUpReferralCode?: string | null;
  status?: HighlighterStatus;
  updatedAt?: string | null;
};
export type Client = {
  id?: string;
  name?: string | null;
  /** ClientPortalId is the PK id specified by ClientDB. It is used to match box tracking to surveys. */
  clientPortalId?: number | null;
  createdAt?: string;
  updatedAt?: string;
};
export type DeliveryStatus =
  | 'NotAssigned'
  | 'InfoReceived'
  | 'InTransit'
  | 'OutForDelivery'
  | 'AttemptFail'
  | 'Delivered'
  | 'AvailableForPickup'
  | 'Exception'
  | 'Expired'
  | 'Pending';
export type Box = {
  createdAt?: string;
  id?: string;
  highlighterId?: string;
  kitId?: number | null;
  project?: Client;
  deliveryStatus?: DeliveryStatus;
  trackingNumber?: string | null;
  deliveryCarrier?: string | null;
  expectedDelivery?: string | null;
};
export type BatchCreateByKitRequest = {
  highlighterIds: string[];
  kitId: number;
};
export type EmailTemplate = {
  id?: number;
  isActive?: boolean;
  name?: string | null;
  subject?: string | null;
  tag?: string | null;
};
export type VerifyAccountAction = 'SendPhoneCode' | 'VerifyPhoneCode';
export type PhoneVerificationMode = 'Call' | 'SMS';
export type VerifyAccountRequest = {
  phoneNumber: string;
  action: VerifyAccountAction;
  code?: string | null;
  channel?: PhoneVerificationMode;
};
export type UpdateHighlighterDateOfBirthRequest = {
  dateOfBirth?: string;
};
export type Components = {
  urbanization?: string | null;
  primary_number?: string | null;
  street_name?: string | null;
  street_predirection?: string | null;
  street_postdirection?: string | null;
  street_suffix?: string | null;
  secondary_number?: string | null;
  secondary_designator?: string | null;
  extra_secondary_number?: string | null;
  extra_secondary_designator?: string | null;
  pmb_designator?: string | null;
  pmb_number?: string | null;
  city_name?: string | null;
  default_city_name?: string | null;
  state_abbreviation?: string | null;
  zipcode?: string | null;
  plus4_code?: string | null;
  delivery_point?: string | null;
  delivery_point_check_digit?: string | null;
};
export type Metadata = {
  record_type?: string | null;
  zip_type?: string | null;
  county_fips?: string | null;
  county_name?: string | null;
  carrier_route?: string | null;
  congressional_district?: string | null;
  building_default_indicator?: string | null;
  rdi?: string | null;
  elot_sequence?: string | null;
  elot_sort?: string | null;
  latitude?: number;
  longitude?: number;
  precision?: string | null;
  time_zone?: string | null;
  utc_offset?: number;
  dst?: boolean;
  ews_match?: boolean;
};
export type Analysis = {
  dpv_match_code?: string | null;
  dpv_footnotes?: string | null;
  dpv_cmra?: string | null;
  dpv_vacant?: string | null;
  dpv_no_stat?: string | null;
  active?: string | null;
  ews_match?: boolean;
  footnotes?: string | null;
  lacslink_code?: string | null;
  lacslink_indicator?: string | null;
  suitelink_match?: boolean;
  enhanced_match?: string | null;
};
export type Candidate = {
  input_id?: string | null;
  input_index?: number;
  candidate_index?: number;
  addressee?: string | null;
  delivery_line_1?: string | null;
  delivery_line_2?: string | null;
  last_line?: string | null;
  delivery_point_barcode?: string | null;
  smarty_key?: string | null;
  components?: Components;
  metadata?: Metadata;
  analysis?: Analysis;
};
export type AddressAnalysisDetails = {};
export type Footnote = {
  note?: string | null;
  description?: string | null;
};
export type AddressAnalysisDetailsRead = {
  deliveryPointValidationResults?: {
    [key: string]: string;
  } | null;
  deliveryPointValidationFootnotes?: {
    [key: string]: string;
  } | null;
  footnotes?: {
    [key: string]: Footnote;
  } | null;
};
export type SmartyStreetsAddressWithValidation = {
  isValid?: boolean;
  isValidAddress?: boolean;
  isPoBox?: boolean;
  isValidState?: boolean;
  address?: Candidate;
  details?: AddressAnalysisDetails;
};
export type SmartyStreetsAddressWithValidationRead = {
  isValid?: boolean;
  isValidAddress?: boolean;
  isPoBox?: boolean;
  isValidState?: boolean;
  address?: Candidate;
  details?: AddressAnalysisDetailsRead;
};
export type ProfileAnswers = {
  questionLabel?: string | null;
  answerTexts?: string[] | null;
};
export type HighlighterAnswer = {
  questionId?: string;
  answerIds?: string[] | null;
};
export type HighlighterNote = {
  createdAt?: string;
  id?: string;
  note?: string | null;
  updatedAt?: string | null;
};
export type BulkCreateNoteResponse = {
  notesCreated?: number;
};
export type BulkCreateNoteRequest = {
  highlighterIds?: string[] | null;
  note?: string | null;
};
export type PossibleAnswer = {
  id?: string;
  description?: string | null;
  slug?: string | null;
};
export type HighlighterQuestion = {
  id?: string;
  title?: string | null;
  description?: string | null;
  allowMultiple?: boolean;
  possibleAnswers?: PossibleAnswer[] | null;
};
export type PatchBulkHighlighterStatusRequest = {
  newStatus: HighlighterStatus;
  highlighterIds?: string[] | null;
};
export type PatchHighlighterDateOfBirthRequest = {
  dateOfBirth?: string;
};
export type PatchHighlighterEmailRequest = {
  email?: string | null;
};
export type SurveyInstanceType = 'optInSurvey' | 'itemSurvey' | 'oneOff' | 'profileSurvey';
export type HighlighterSurvey = {
  linkUrl?: string | null;
  id?: string | null;
  surveyId?: string;
  imageUrl?: string | null;
  name?: string | null;
  status?: string | null;
  type?: SurveyInstanceType;
  customData?: {
    [key: string]: string;
  } | null;
  deadline?: string;
};
export type HighlighterSurveyRead = {
  linkUrl?: string | null;
  id?: string | null;
  surveyId?: string;
  imageUrl?: string | null;
  name?: string | null;
  status?: string | null;
  type?: SurveyInstanceType;
  customData?: {
    [key: string]: string;
  } | null;
  deadline?: string;
  isOverdue?: boolean;
  isVisible?: boolean;
};
export type SurveyType = 'optInSurvey' | 'oneOff' | 'itemSurvey' | 'profileSurvey';
export type PatchHighlighterSurveyRequest = {
  status: string;
};
export type HighlighterSurveyAdmin = {
  project?: Client;
  /** Let UI know if this is legacy or not.
    Legacy means the Survey comes from: dispatched_item_surveys */
  isLegacy?: boolean;
  linkUrl?: string | null;
  id?: string | null;
  surveyId?: string;
  imageUrl?: string | null;
  name?: string | null;
  status?: string | null;
  type?: SurveyInstanceType;
  customData?: {
    [key: string]: string;
  } | null;
  deadline?: string;
};
export type HighlighterSurveyAdminRead = {
  project?: Client;
  /** Let UI know if this is legacy or not.
    Legacy means the Survey comes from: dispatched_item_surveys */
  isLegacy?: boolean;
  linkUrl?: string | null;
  id?: string | null;
  surveyId?: string;
  imageUrl?: string | null;
  name?: string | null;
  status?: string | null;
  type?: SurveyInstanceType;
  customData?: {
    [key: string]: string;
  } | null;
  deadline?: string;
  isOverdue?: boolean;
  isVisible?: boolean;
};
export type HighlighterTag = {
  /** This is the date the highlighter was assigned this tag.
    This is NOT the date the tag was created */
  createdAt?: string;
  description?: string | null;
  id?: string;
  title?: string | null;
};
export type BulkAssociateTagResponse = {
  highlightersEffected?: number;
};
export type BulkAssociateTagRequest = {
  highlighterIds: string[];
};
export type PhoneNumberAvailabilityResult = {
  isAvailable?: boolean;
};
export type PhoneNumberAvailabilityRequest = {
  /** Ten-digit string representation of a phone number. e.g., 1231231234 */
  phoneNumber?: string | null;
};
export type Survey = {
  deadline?: string;
  /** DurationDays should only be set for item surveys. */
  durationDays?: number | null;
  /** Link to the 3rd party survey. */
  linkUrl?: string | null;
  id?: string | null;
  imageUrl?: string | null;
  name?: string | null;
  createdAt?: string;
  updatedAt?: string;
  type?: SurveyType;
  instanceAddedBrevoTemplateId?: number | null;
  reminderBrevoTemplateId?: number | null;
  clientId?: string | null;
  internalName?: string | null;
  client?: Client;
  nudges?: string[] | null;
};
export type Recruit = {
  userid?: string;
  region?: string | null;
  state?: string | null;
  age?: number;
  pronouns?: string | null;
  ethnicity?: string | null;
  income?: string | null;
  whoYouShopFor?: string | null;
  parentalStatus?: string | null;
  employmentStatus?: string | null;
  employmentIndustry?: string | null;
  workoutFrequency?: string | null;
  relationshipStatus?: string | null;
  shoppingPreferences?: string | null;
  education?: string | null;
  betterForYou?: string | null;
  plantForwardness?: string | null;
  foodRestrictions?: string | null;
  sneakerBrands?: string | null;
  waterBrands?: string | null;
  techSavviness?: string | null;
  hearstAudiences?: string | null;
  extraTasks?: string | null;
  remindersType?: string | null;
  onlineShopping?: string | null;
  groceryShopping?: string | null;
  bigBoxShopping?: string | null;
  convenienceShopping?: string | null;
  allergy?: string | null;
  groceryShopped?: string | null;
  completedIHUTs?: number;
  isHighlightEmployee?: boolean;
  tags?: string[] | null;
};
export type RecruitingFilter = {
  allowHighlightEmployees?: boolean | null;
  sendOnlyToHighlightEmployees?: boolean | null;
  excludeActiveItemSurvey?: boolean | null;
  excludeActiveOptInSurvey?: boolean | null;
  excludeActiveOneOffSurvey?: boolean | null;
  excludePendingItemSurvey?: boolean | null;
  excludeItemSurveyDeadlineDateTimeRangeStart?: string | null;
  excludeItemSurveyDeadlineDateTimeRangeEnd?: string | null;
  completedIHUTsMin?: number | null;
  completedSurveysMin?: number | null;
  completedSurveysMax?: number | null;
  dontUseForBRS?: boolean | null;
  dontUseForIDIs?: boolean | null;
  dontUseForPhotos?: boolean | null;
  dontUseForProductReturns?: boolean | null;
  dontUseForVideos?: boolean | null;
  highlighterIds?: string[] | null;
  gender?: string | null;
  employment_status?: string | null;
  employment_industry?: string | null;
  household_income?: string | null;
  ethnicity?: string | null;
  food_restrictions?: string | null;
  workout_frequency?: string | null;
  relationship_status?: string | null;
  sneaker_brands?: string | null;
  water_brands?: string | null;
  tech_savviness?: string | null;
  shopping_preferences?: string | null;
  hearst_audiences?: string | null;
  extra_tasks?: string | null;
  reminders_type?: string | null;
  education?: string | null;
  parental_status?: string | null;
  purchasing_power?: string | null;
  online_shopping?: string | null;
  grocery_shopping?: string | null;
  big_box_shopping?: string | null;
  convenience_shopping?: string | null;
  better_for_you?: string | null;
  plant_forwardness?: string | null;
  allergy?: string | null;
  grocery_shopped?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  regions?: string | null;
  states?: string | null;
};
export type RecruitCount = {
  count?: number;
};
export type InternalRecruitingOptions = {
  sendToHighlightEmployeesOnly?: boolean;
  includeHighlightEmployees?: boolean;
  excludeProjectIds?: number[] | null;
};
export type FilterHighlightersRequest = {
  highlighterIds?: string[] | null;
  options?: InternalRecruitingOptions;
};
export type HighlighterProfile = {
  id?: string;
  region?: string | null;
  state?: string | null;
  age?: number;
  email?: string | null;
  isHighlightEmployee?: boolean;
  tags?: string[] | null;
  completedIHUTs?: number;
  completedSurveys?: number;
  questionsAndAnswers?: {
    [key: string]: string[];
  } | null;
};
export type HighlighterForOptIn = {
  id?: string;
  email?: string | null;
  isHighlightEmployee?: boolean;
  tags?: string[] | null;
  completedIHUTs?: number;
  completedSurveys?: number;
  optInStatus?: string | null;
  optInDate?: string;
};
export type ReferralCode = {
  id?: string;
  code?: string | null;
  active?: boolean;
  expiration?: string | null;
  timesUsed?: number;
  maxTimesUsed?: number | null;
  customerId?: string | null;
  notes?: string | null;
  createdAt?: string;
  updatedAt?: string | null;
  oneTimeUse?: boolean;
  /** IsRecruitable indicates whether Highlighters who signed up with this code should be included
    in recruiting queries. */
  isRecruitable?: boolean;
};
export type ReferralCodeValidationResult = {
  isValid?: boolean;
};
export type SurveyInstanceResponseStatus = 'success' | 'failed';
export type SurveyInstanceResponse = {
  customerId?: string;
  status?: SurveyInstanceResponseStatus;
  detail?: string | null;
};
export type CreateSurveyInstanceResult = {
  emailsSent?: boolean;
  surveyInstances?: SurveyInstanceResponse[] | null;
};
export type ItemSurveyBox = {
  expectedDelivery?: string;
};
export type SurveyInstance = {
  id?: string;
  customerId?: string;
  email?: string | null;
  surveyId?: string;
  type?: SurveyInstanceType;
  /** Deadline is null when this object is created by a RETURNING statement. */
  deadline?: string | null;
  createdAt?: string;
  updatedAt?: string;
  box?: ItemSurveyBox;
  status?: string | null;
  customData?: {
    [key: string]: string;
  } | null;
};
export type SurveyInstanceRead = {
  id?: string;
  customerId?: string;
  email?: string | null;
  surveyId?: string;
  type?: SurveyInstanceType;
  /** Deadline is null when this object is created by a RETURNING statement. */
  deadline?: string | null;
  createdAt?: string;
  updatedAt?: string;
  box?: ItemSurveyBox;
  status?: string | null;
  customData?: {
    [key: string]: string;
  } | null;
  /** IsVisibleToHighlighter depends on nullable fields and is set to false when visibility is unknown. */
  isVisibleToHighlighter?: boolean;
  isStatusTerminal?: boolean;
};
export type PatchSurveyInstanceRequest = {
  status: string;
};
export type SurveyAction = 'Cancel' | 'Expire' | 'Nudge' | 'Refresh';
export type SurveyActionRequest = {
  action: SurveyAction;
  customerIds?: string[] | null;
};
export type AgeRange = {
  min?: number | null;
  max?: number | null;
};
export type ParticipantFilter = {
  allowHighlightEmployees?: boolean;
  sendOnlyToHighlightEmployees?: boolean;
  ageMin?: number | null;
  ageMax?: number | null;
  ageRanges?: AgeRange[] | null;
  regions?: string[] | null;
  excludedRegions?: string[] | null;
  states?: string[] | null;
  excludedStates?: string[] | null;
  questionsAndAnswers?: {
    [key: string]: string[];
  } | null;
  excludedQuestionsAndAnswers?: {
    [key: string]: string[];
  } | null;
  completedSurveysMin?: number;
  completedIHUTsMin?: number;
  requiresVideoSubmission?: boolean | null;
  requiresPhotoSubmission?: boolean | null;
  requiresIDI?: boolean | null;
  requiresProductReturn?: boolean | null;
  potentialParticipants?: string[] | null;
};
export type FillSurveyRequest = {
  newOptInsToSend?: number;
  participantFilter?: ParticipantFilter;
  excludeProjectIds?: number[] | null;
};
export type FillOptInSurveyResult = {
  highlighterIds?: string[] | null;
  filledByPartitionRules?: number;
  filledByTestGroupRules?: number;
};
export type Partition = {
  toRecruit?: number;
  allowedAnswers?: string[] | null;
  allowedAgeRanges?: AgeRange[] | null;
};
export type FillPartitionedRequest = {
  testGroupName?: string | null;
  minOptInsToSend?: number;
  partitionGroups?: {
    [key: string]: Partition[];
  } | null;
  participantFilter?: ParticipantFilter;
  excludeProjectIds?: number[] | null;
};
export type TokenResponse = {
  access_token?: string | null;
  expires_in?: number;
};
export type TokenResponseRead = {
  access_token?: string | null;
  token_type?: string | null;
  expires_in?: number;
};
export type TokenRequest = {
  grant_type?: string | null;
  assertion?: string | null;
};
export const {
  usePostAftershipTrackingsMutation,
  useGetMyHighlighterAccountsQuery,
  usePostMyHighlighterAccountsMutation,
  usePostAdminProjectsByProjectIdBoxesBatchCreateMutation,
  usePostAdminProjectsByProjectIdBoxesBatchCreateByKitMutation,
  useGetAdminProjectsByProjectIdBoxesQuery,
  useDeleteAdminBoxesByBoxIdMutation,
  useGetAdminClientsQuery,
  useGetAdminEmailQuery,
  useGetHealthQuery,
  usePutMyHighlighterAccountsMutation,
  usePutMyHighlighterAccountsVerificationMutation,
  usePostMyHighlighterAccountsBirthdateMutation,
  usePostMyHighlighterAccountsActivateMutation,
  usePutMyHighlighterAddressesMutation,
  usePostMyHighlighterAddressesMutation,
  usePostMyHighlighterAddressesVerificationMutation,
  usePutAdminHighlightersByHighlighterIdAddressMutation,
  usePostAdminHighlightersByHighlighterIdAddressMutation,
  usePostAdminAddressesVerificationMutation,
  useGetMyHighlighterAnswersSurveysBySurveyIdAnswersQuery,
  useGetMyHighlighterAnswersQuery,
  usePutMyHighlighterAnswersMutation,
  useGetAdminHighlightersByHighlighterIdNotesQuery,
  usePostAdminHighlightersByHighlighterIdNotesMutation,
  usePostAdminNotesMutation,
  useDeleteAdminNotesByNoteIdMutation,
  useGetMyHighlighterQuestionsQuery,
  useGetAdminHighlighterQuestionsQuery,
  useGetAdminHighlightersByIdQuery,
  useGetAdminHighlightersQuery,
  useDeleteAdminHighlightersMutation,
  usePostAdminHighlightersBulkGetMutation,
  useGetAdminHighlightersByHighlighterIdBoxesQuery,
  usePatchAdminHighlightersStatusMutation,
  usePatchAdminHighlightersByHighlighterIdDateofbirthMutation,
  usePatchAdminHighlightersByHighlighterIdEmailMutation,
  useGetMyHighlighterSurveysQuery,
  usePatchMyHighlighterSurveysBySurveyInstanceIdMutation,
  useGetAdminHighlightersByHighlighterIdSurveysQuery,
  useGetAdminTagsQuery,
  useGetAdminHighlightersByHighlighterIdTagsQuery,
  useDeleteAdminHighlightersByHighlighterIdTagsAndTagIdMutation,
  usePatchAdminTagsByTagIdMutation,
  usePostMyPhoneNumbersAvailabilityMutation,
  usePostAuthProjectOrchestratorAuthCheckMutation,
  useGetAdminProjectsByIdSurveysQuery,
  usePostAdminRecruitingQueryMutation,
  useGetAdminRecruitingQuery,
  useGetAdminRecruitingCountQuery,
  usePostAdminRecruitingFilterMutation,
  useGetAdminRecruitingByProjectIdCompletedRecruitsQuery,
  useGetAdminRecruitingSurveysBySurveyIdProfilesQuery,
  useGetAdminRecruitingSurveysBySurveyIdHighlightersQuery,
  useGetAdminReferralCodesQuery,
  usePostAdminReferralCodesMutation,
  useGetAdminReferralCodesByIdQuery,
  usePutAdminReferralCodesByReferralCodeIdMutation,
  useGetReferralCodesValidateByCodeQuery,
  usePostAdminSurveysBySurveyIdSurveyInstancesMutation,
  useGetAdminSurveysBySurveyIdSurveyInstancesQuery,
  useGetAdminSurveysBySurveyIdAllSurveyInstancesQuery,
  usePatchAdminSurveyInstancesBySurveyInstanceIdMutation,
  useGetAdminSurveyInstancesBySurveyInstanceIdQuery,
  usePostAdminSurveysMutation,
  useGetAdminSurveysQuery,
  usePutAdminSurveysByIdMutation,
  useGetAdminSurveysByIdQuery,
  usePutAdminSurveysBySurveyIdActionsMutation,
  usePostAdminSurveysBySurveyIdFillMutation,
  usePostAdminSurveysByProfileSurveyIdSendprofilesurveyMutation,
  usePostAdminSurveysBySurveyIdFillpartitionedMutation,
  usePostAdminSurveysBySurveyIdNudgeMutation,
  usePostAdminSurveysBySurveyIdFailedQcMutation,
  usePostAuthTokenMutation,
} = injectedRtkApi;
