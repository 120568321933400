export enum ProfileSurveyStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  NOT_STARTED = 'NOT_STARTED',
  DISABLED = 'DISABLED',
}

export type ProfileTileIconMap<K extends ProfileSurveyStatus> = {
  [P in K]?: React.ReactNode;
};
