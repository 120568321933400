import { combineReducers } from '@reduxjs/toolkit';

import { highlighterApi } from '@highlight-cp/highlighter-api';

import { appSlice } from './app/app-slice';

export const reducer = combineReducers({
  [appSlice.name]: appSlice.reducer,

  [highlighterApi.reducerPath]: highlighterApi.reducer,
});
