import type { ErrorInfo, PropsWithChildren, ReactNode } from 'react';
import React, { Component } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { FiAlertOctagon } from 'react-icons/fi';

import { Alert } from '../alert';
import { Button } from '../button';
import { Center } from '../layout';

interface ErrorBoundaryOptions {
  componentName?: string;
  title?: string;
}

interface ErrorBoundaryProps {
  children: ReactNode;
  options?: ErrorBoundaryOptions;
}

interface State {
  hasError: boolean;
}

const DEFAULT_TITLE = 'Oops, there is an error!';

export class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = errorInfo?.componentStack ?? undefined;
    renderingError.cause = error;

    datadogRum.addError(renderingError);
  }

  handleTryAgain = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { options } = this.props;
    // Check if the error is thrown
    if (this.state.hasError) {
      const icon = <FiAlertOctagon />;
      return (
        <Center>
          <Alert variant="light" color="red" title={options?.title || DEFAULT_TITLE} icon={icon}>
            Sorry, we ran into an issue rendering{' '}
            {options?.componentName ? `the ${options?.componentName}` : 'this'} component. Please
            refresh the page, or{' '}
            <Button color="red" onClick={this.handleTryAgain} size="xs">
              try again
            </Button>
          </Alert>
        </Center>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

/**
 * withErrorBoundary
 * ---
 * @description
 * Wraps a component with an error boundary.
 * @example
 * ```tsx
 * const MyComponent = withErrorBoundary(MyComponent, { componentName: 'MyComponent' });
 * ```
 * @param WrappedComponent - The component to wrap.
 * @param options - The options for the error boundary.
 * @param options.componentName - The name of the component to wrap.
 * @param options.title - The title of the error boundary.
 * @returns The wrapped component.
 */
export const withErrorBoundary = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  options?: ErrorBoundaryOptions,
): React.ComponentType<PropsWithChildren<P & Partial<ErrorBoundaryProps>>> => {
  const ErrorBoundaryWrapper: React.ComponentType<
    PropsWithChildren<P & Partial<ErrorBoundaryProps>>
  > = (props) => {
    return (
      <ErrorBoundary options={options}>
        <WrappedComponent {...(props as P)} />
      </ErrorBoundary>
    );
  };
  return ErrorBoundaryWrapper;
};
