/**
 * Docs: https://redux-toolkit.js.org/api/configureStore
 *
 *  next-redux-wrapper: https://github.com/kirill-konshin/next-redux-wrapper
 */

// import type { CombinedState } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { Context } from 'next-redux-wrapper';
import { createWrapper } from 'next-redux-wrapper';

import { highlighterApi } from '@highlight-cp/highlighter-api';

import { reducer } from './reducer';
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger';

let _store: AppStore;

const makeStore = (_context: Context) => {
  const tempStore = configureStore({
    reducer,

    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: { extraArgument: {} },
      })
        .concat(highlighterApi.middleware)
        .concat(rtkQueryErrorLogger);
    },
  });

  _store = tempStore;

  return tempStore;
};

// needed to bypass '$CombinedState' from external module error
// tsconfig declaration: false fixes this too
// type CleanState<T> = T extends CombinedState<infer S> ? { [K in keyof S]: CleanState<S[K]> } : T;

export type AppStore = ReturnType<typeof makeStore>;
// export type RootState = CleanState<ReturnType<AppStore['getState']>>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const reduxWrapper = createWrapper<AppStore>(makeStore, {
  debug: false, //process.env.NODE_ENV === "development",
});

export { _store as store };
