import { isRejected, isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware, MiddlewareAPI, UnknownAction } from '@reduxjs/toolkit';

import { error } from '../logger';

interface ActionPayload extends UnknownAction {
  payload: {
    message: string;
    [k: string]: unknown;
  };
}

const isActionWithMessage = (action: unknown): action is ActionPayload => {
  return !!(action as ActionPayload).payload?.message;
};

export const rtkQueryErrorLogger: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
  if (!isActionWithMessage(action)) {
    return next(action);
  }

  if (isRejectedWithValue(action)) {
    // 4xx or 5xx errors
    error('api error', { message: action.payload?.message, action });
  }

  if (isRejected(action)) {
    // hasura errors
    error('api error', { message: action.payload?.message, action });
  }

  return next(action);
};
